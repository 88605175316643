import { type LinksFunction, type LoaderFunction, type MetaFunction, json } from '@remix-run/node';
import {
	Links,
	LiveReload,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	isRouteErrorResponse,
	useLoaderData,
	useRouteError,
} from '@remix-run/react';
import { isValidTimeZone } from '@ui/utils/timezones';
import { useContext } from 'react';
import { NavBar } from '~/components/NavBar';
import type { Onboarding } from '~/db/onboarding.server';
import { sessionStorage } from '~/session/session.server';
import { ToastProvider } from '~/shared/toast';
import type { ToastObject } from '~/shared/toast/ToastProvider';
import type { User } from './db/user.server';
import { getUser } from './session/auth/user';
import { OnboardingSession, type OnboardingViewType } from './session/onboarding.server';
import { NonceContext } from './shared/nonce-context';
import globalStylesheetUrl from './styles/global.css?url';
import tailwindStylesheetUrl from './styles/tailwind.css?url';

export const links: LinksFunction = () => {
	return [
		{ rel: 'stylesheet', href: tailwindStylesheetUrl },
		{ rel: 'stylesheet', href: globalStylesheetUrl },
		{
			rel: 'stylesheet',
			href: 'https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900', // All tailwind weights
		},
		// {
		//   rel: "stylesheet",
		//   href: "https://fonts.googleapis.com/css2?family=Hind%20Vadodara:wght@100;200;300;400;500;600;700;800;900", // All tailwind weights
		// },
		// {
		//   rel: "stylesheet",
		//   href: "https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900", // All tailwind weights
		// },
		{
			rel: 'stylesheet',
			href: 'https://fonts.googleapis.com/css2?family=Neuton:wght@100;200;300;400;500;600;700;800;900', // All tailwind weights
		},
	];
};

export const meta: MetaFunction = () => [
	{
		title: 'Coverage Optimizer',
	},
];

export const loader: LoaderFunction = async ({ request }) => {
	const session = await sessionStorage.getSession(request.headers.get('cookie'));

	const tz = request.headers.get('Cookie')?.match(/tz=([^\s;]+)/)?.[1];
	const toast = session.get('toast') || null;

	return json(
		{
			user: await getUser(request),
			onboarding: await OnboardingSession.getOnboarding(request),
			onboardingView: await OnboardingSession.getOnboardingView(request),
			toast,
			tz: isValidTimeZone(tz) ? tz : null,
			ENV: {
				SENTRY_DSN: process.env.SENTRY_DSN,
				NODE_ENV: process.env.NODE_ENV,
			},
		},
		{
			headers: {
				'Set-Cookie': await sessionStorage.commitSession(session), //will remove the flash message for you
				// "Set-Cookie": await commitSession(session, { maxAge: SESSION_MAX_AGE }), //re set max age if you previously set a max age for your sessions.
			},
		},
	);
};

export default function App() {
	const nonce = useContext(NonceContext);

	const data = useLoaderData<{
		user: User | null;
		toast: ToastObject | null;
		onboarding: Onboarding | null;
		onboardingView: OnboardingViewType;
		tz: string | null;
		ENV: Record<string, string>;
	}>();

	return (
		<html lang="en" className="h-full">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<Meta />
				<Links />
			</head>
			<body className="h-full text-primary-main">
				<ToastProvider toast={data.toast}>
					<main className={'w-full min-h-screen'}>
						<NavBar onboardingView={data.onboardingView} />
						<Outlet />
					</main>
				</ToastProvider>
				<ScrollRestoration nonce={nonce} />
				<script
					nonce={nonce}
					// biome-ignore lint/security/noDangerouslySetInnerHtml: need to set window env
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(data.ENV)}`,
					}}
				/>
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>
	);
}

export function ErrorBoundary() {
	const error = useRouteError();

	if (isRouteErrorResponse(error)) {
		return (
			<html lang={'en'}>
				<head>
					<title>Oops!</title>
					<Meta />
					<Links />
				</head>
				<body className={'p-16'}>
					<h1 className={'font-heading text-xl'}>
						{error.status} {error.statusText}
					</h1>
					<Scripts />
				</body>
			</html>
		);
	} else {
		return (
			<html lang={'en'}>
				<head>
					<title>Oops!</title>
					<Meta />
					<Links />
				</head>
				<body className={'p-16'}>
					<div>
						<h1>Uh oh ...</h1>
						<p>Something went wrong.</p>
						<pre>{(error as Error)?.message}</pre>
					</div>
					<Scripts />
				</body>
			</html>
		);
	}
}
