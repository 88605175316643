import { Form, Link, useMatches, useSubmit } from '@remix-run/react';
import { QuestionTooltip } from '@ui/components/QuestionTooltip';
import { LinkButton } from '@ui/components/inputs/LinkButton';
import { Radio } from '@ui/components/inputs/Radio';
import { clsx } from '@ui/utils/styles';
import type React from 'react';
import type { OnboardingViewType } from '~/session/onboarding.server';
import {
	CoverageOptimizer,
	CoverageOptimizerWhite,
	PoweredByKmodo,
	PoweredByKmodoDark,
} from '~/shared/svgs';
import { useOptionalUser } from '~/shared/utils';

interface NavBarProps {
	onboardingView: OnboardingViewType;
}

const darkStyle = {
	background: 'linear-gradient(269.24deg, #000348 0%, #262C9D 99.49%)',
};

export const NavBar: React.FC<NavBarProps> = (props) => {
	const { onboardingView } = props;
	const matchingRoutes = useMatches();
	const isAtOnboarding = matchingRoutes.some((x) => x.id === 'routes/onboarding');
	const isAtOnboardingComplete = matchingRoutes.some((x) => x.id === 'routes/onboarding.complete');
	const isAtAccount = matchingRoutes.some((x) => x.id === 'routes/account');

	const maybeUser = useOptionalUser();

	const Logo = isAtOnboarding ? CoverageOptimizerWhite : CoverageOptimizer;
	const PoweredBy = isAtOnboarding ? PoweredByKmodo : PoweredByKmodoDark;
	const submit = useSubmit();

	return (
		<div
			className={clsx('p-4 md:px-16 ', {
				'border-b': !isAtOnboarding,
			})}
			style={isAtOnboarding ? darkStyle : undefined}
		>
			<div className={'flex justify-between items-center py-2'}>
				<Link to={'/'}>
					<Logo className={'h-[25px] md:h-[35px]'} />
				</Link>
				<PoweredBy className={'h-[45px] md:h-[65px]'} />
			</div>
			<div className={'flex items-center gap-2'}>
				{maybeUser && !isAtAccount ? (
					<LinkButton mode="secondary" className={'btn-sm'} to={'/account'}>
						Go To My Account
					</LinkButton>
				) : null}
				{isAtOnboarding && !isAtOnboardingComplete ? (
					<Form
						action="/onboarding"
						method="post"
						className={'flex items-center gap-1'}
						onChange={(e) => submit(e.currentTarget)}
					>
						<input type={'hidden'} id={'_action'} name={'_action'} value={'view'} />
						<Radio
							name={'onboarding-view'}
							defaultValue={onboardingView}
							items={[
								{ value: 'onboarding', label: 'Onboarding' },
								{ value: 'dashboard', label: 'Dashboard' },
							]}
						/>
						<QuestionTooltip
							className={'text-xl text-white'}
							tooltipClassName={'text-primary-main'}
							tooltip={
								'Clicking the Onboarding button takes you through a process to answer the questions one at a time with some explanation about the question and “Did You Know” insights.\n\n' +
								'Clicking on the Dashboard button takes you to one screen to input all your answers. This is the faster route and designed for those familiar with the reasons for the various questions.\n\n' +
								'You can switch between the Onboarding and Dashboard approaches at any time.'
							}
						/>
					</Form>
				) : null}
			</div>
		</div>
	);
};
